@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';

@charset "UTF-8";
@import url("font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic|Open+Sans+Condensed:700");

/*
	TXT by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

@import "libs/skel";

@include skel-breakpoints((
        desktop: '(min-width: 737px)',
        tablet: '(min-width: 737px) and (max-width: 1200px)',
        mobile: '(max-width: 736px)'
));

@include skel-layout((
        reset: 'full',
        boxModel: 'border',
        grid: true,
        conditionals: true,
        containers: true,
        breakpoints: (
                desktop: (
                        containers: 1200px,
                        grid: (
                                gutters: (50px, 50px)
                        ),
                ),
                tablet: (
                        containers: 960px,
                        grid: (
                                gutters: (25px, 25px)
                        ),
                ),
                mobile: (
                        containers: (100%, true),
                        grid: (
                                gutters: (20px, 20px)
                        ),
                )
        )
));

/* Basic */

body {
  background: #f2f5f3;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #6b7770;
  font-size: 11.5pt;
  line-height: 2em;

  &.is-loading * {
    @include vendor('transition', 'none !important');
    @include vendor('animation', 'none !important');
  }
}

input, textarea, select {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #6b7770;
  font-size: 11.5pt;
  line-height: 2em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #6b7770;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  text-decoration: none;
  color: inherit;
}

h2 {
  &.major {
    position: relative;
    border-top: solid 5px #e7eae8;

    span {
      background: #fff;
      position: relative;
      display: inline-block;
    }
  }
}

a {
  color: #449038;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

span {
  &.float-right {
    float: right;
    margin-right: 0.5em;
  }
}

b, strong {
  font-weight: 700;
  color: #6b7770;
}

i, em {
  font-style: italic;

  &.float-right {
    float: right;
    margin: 0.3em 0 0 0;
  }
}

br.clear {
  clear: both;
}

sub {
  position: relative;
  top: 0.5em;
  font-size: 0.8em;
}

sup {
  position: relative;
  top: -0.5em;
  font-size: 0.8em;
}

hr {
  border: 0;
  border-top: solid 2px #E7EAE8;
  margin: 0 0 2em 0;
}

blockquote {
  border-left: solid 5px #E7EAE8;
  padding: 1em 0 1em 1.5em;
  font-style: italic;
}

p, ul, ol, dl, table {
  margin-bottom: 3em;
}

/* Section/Article */

section, article {
  margin-bottom: 6em;

  > :last-child,
  &:last-child {
    margin-bottom: 0;
  }
}

header {
  > p {
    display: block;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #c1cac5;
  }
}

/* Image */

.image {
  position: relative;
  display: inline-block;
  border-radius: 8px;

  img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }

  &.no-overlay {
    background: none;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  &:after {
    background: none;
  }

  &.fit {
    display: block;
    width: 100%;
  }

  &.featured {
    display: block;
    width: 100%;
    margin: 0 0 2em 0;
    background: url('images/overlay.png');
  }

  &.left {
    float: left;
    margin: 0 2em 2em 0;
  }

  &.centered {
    display: block;
    margin: 0 0 2em 0;

    img {
      margin: 0 auto;
      width: auto;
    }
  }
}

/* Button */

input[type="button"],
input[type="submit"],
input[type="reset"],
button,
.button {
  -webkit-appearance: none;
  display: inline-block;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  background: #b1ddab;
  color: #fff;
  border: 0;
  line-height: 1em;
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  -moz-transition: background-color .2s ease-in-out;
  -webkit-transition: background-color .2s ease-in-out;
  -o-transition: background-color .2s ease-in-out;
  -ms-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;

  &:hover {
    background: #c1edbb;
  }

  &:active {
    background: #a1cd9b;
  }

  &.alt {
    background: #c1cac5;

    &:hover {
      background: #ccd5d0;
    }

    &:active {
      background: #b1bab5;
    }
  }

  &.small {
    line-height: 1.3em;
    font-size: 1em;
    width: 3em;

    &.negative {
      background: #fac1c6;

      &:hover {
        background: #fdd1d5;
      }
    }
  }

}

/* List */

ul {
  &.default {
    list-style: none;
    padding-left: 2em;

    li {
      padding-left: 0.5em;
      margin: 3px 0 3px 0;

    }

    li:before {
      font-family: 'FontAwesome';
      margin: 0 0.9em 0 -1.2em;
    }

    &.positive {
      background: #b1ddab;
      margin-bottom: 0;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      li:before {
        content: '\f067';
      }
    }

    &.negative {
      background: #fac1c6;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      li:before {
        content: '\f068';
      }
    }

    &.info {
      background: #e3f3bc;
      border-radius: 6px;
      margin-top: -2em;

      li:before {
        content: '\f054';
      }
    }

    &.checklist {
      display: inline-block;
      margin-top: -1em;
      padding-right: 0.5em;

      li:before {
        content: '\f00c';
      }

      li {
        border-bottom: 1px solid #b1ddab;
      }

      li:last-child {
        border-bottom: none;
      }
    }
  }

  &.box {
    list-style: none;
    padding: 0 0.5em 0 0.5em;

    &.recipe {
      //border: 1px solid #b1ddab;
      background: #D4EED0;
      margin-bottom: 0;
      border-radius: 6px;

      li {
        border-bottom: 1px solid #b1ddab;
      }

      li:last-child {
        border-bottom: none;
      }
    }

    &.nutrition {
      background: #D4EED0;
      margin-bottom: 0;
      border-radius: 6px;

      li {
        border-bottom: 1px solid #b1ddab;

        &.sub-category {
          margin-left: 1em;
        }
      }

      li:last-child {
        border-bottom: none;
      }
    }
  }

  &.divided {
    > li {
      border-top: solid 2px #e7eae8;
      padding-top: 2.35em;
      margin-top: 2.35em;

      &:first-child {
        border-top: 0;
        padding-top: 0;
        margin-top: 0;
      }
    }
  }

  &.actions {
    li {
      display: inline-block;
      margin: 0 0 0 1em;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.contact {
    cursor: default;

    li {
      @include vendor('transition', 'top .15s ease-in-out');
      position: relative;
      display: inline-block;
      margin: 0 0.25em 0 0.25em;
      top: 0;

      a {
        @include vendor('transition', 'background-color .2s ease-in-out');
        display: block;
        position: relative;
        width: 48px;
        height: 48px;
        border-radius: 6px;
        outline: 0;
        background: #c1cac5;
        text-align: center;

        &:before {
          color: #f2f5f3;
          font-size: 32px;
          line-height: 48px;
        }
      }

      &:hover {
        top: -5px;

        a {
          &.fa-facebook {
            background-color: #3C5A98;
          }

          &.fa-twitter {
            background-color: #2DAAE4;
          }

          &.fa-dribbble {
            background-color: #C4376B;
          }

          &.fa-linkedin {
            background-color: #006599;
          }

          &.fa-instagram {
            background-color: #2E5E86;
          }

          &.fa-google-plus {
            background-color: #DA2713;
          }
        }
      }

      &:active {
        top: 0;
      }
    }
  }

  &.special {
    cursor: default;

    li {
      @include vendor('transition', 'top .15s ease-in-out');
      position: relative;
      display: inline-block;
      margin: 0 1.5em 0 1.5em;
      top: 0;

      &:hover {
        top: -10px;

        a {
          background: #b9d2ce;
        }
      }
    }

    a {
      @include vendor('transition', 'background-color .2s ease-in-out');
      display: block;
      position: relative;
      background: #c1cac5;
      width: 12em;
      height: 12em;
      border-radius: 6em;
      outline: 0;

      &:before {
        position: absolute;
        width: 128px;
        height: 128px;
        font-size: 96px;
        line-height: 128px;
        left: 50%;
        top: 50%;
        margin-top: -64px;
        margin-left: -64px;
        color: #fff;
        text-align: center;
      }
    }
  }

  &.meta {
    font-size: 0.85em;
    color: #a1aaa5;

    li {
      position: relative;
      display: inline-block;
      margin-left: 1em;

      a {
        color: inherit;
        outline: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &:before {
        opacity: 0.5;
        cursor: default;
        margin-right: 0.5em;
      }
    }
  }

  &.menu {
    li {
      border-left: solid 1px #E7EAE8;
      display: inline-block;
      padding: 0 0 0 0.75em;
      margin: 0 0 0 0.75em;

      &:first-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }

}

ol {
  &.default {
    list-style: decimal;
    padding-left: 1.25em;

    li {
      padding-left: 0.25em;
    }
  }
}

/* Forms */

form {
  label {
    display: block;
    margin: 0 0 1em 0;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #6b7770;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select,
  textarea {
    -webkit-appearance: none;
    display: block;
    width: 100%;
    border-radius: 8px;
    border: solid 2px #E7EAE8;

    &:focus {
      border-color: #a1cd9b;
      outline: none;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select {
    line-height: 3em;
    padding: 0 1em;
  }

  textarea {
    min-height: 9em;
    padding: 1em;
  }

  .formerize-placeholder {
    color: #555 !important;
  }

  ::-webkit-input-placeholder {
    color: #555 !important;
  }

  :-moz-placeholder {
    color: #555 !important;
  }

  ::-moz-placeholder {
    color: #555 !important;
  }

  :-ms-input-placeholder {
    color: #555 !important;
  }

  ::-moz-focus-inner {
    border: 0;
  }
}

/* General Inputs */

input[type="text"],
input[type="number"],
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  border-radius: 8px;
  border: solid 2px #E7EAE8;
  line-height: 3em;
  padding: 0 1em;

  &.small {
    width: 5em;
    line-height: 2.8em;
  }

  &:focus {
    border-color: #a1cd9b;
    outline: none;
  }
}

//remove arrows on number textfield
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Tables */

table {
  width: 100%;

  &.default {

    &.half-width {
      width: 50%;
    }

    &.tight {
      td {
        padding: 0.2em 1em 0.2em 1em;
      }
    }

    tr {
      border-top: solid 2px #E7EAE8;

      &:first-child {
        border-top: 0;
      }
    }

    tr:hover {
      background-color: #c1edbb;
    }

    tr:first-child:hover {
      background-color: white;
    }

    td {
      padding: 0.5em 1em 0.5em 1em;
    }

    th {
      text-align: left;
      padding: 0.5em 1em 0.5em 1em;
      margin: 0 0 1em 0;
      font-family: 'Open Sans Condensed', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      color: #6b7770;
    }

    thead {
      background: #E7EAE8;
      color: #fff;
    }
  }
}

/* Box */

.box {
  &.highlight {
    text-align: center;
  }
}

.message {
  border: solid 2px;
  padding: 1.5em;
  margin: 1em 0 1em 0;
  border-radius: 4px;

  &.success {
    border-color: #b1ddab;
  }

  &.error {
    border-color: #fac1c6;
  }
}

.config {
  border: 2px solid #e7eae8;
  padding: 1.5em;
  margin: 1em 0 1em 0;
  border-radius: 8px;
}

.controls {
  margin-bottom: 2em;
}

/* Icons */

.icon {
  position: relative;
  text-decoration: none;

  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
  }

  > .label {
    display: none;
  }
}

/* Wrappers */

#banner-wrapper {
  position: relative;
  background-image: url('images/overlay.png'), url('images/overlay.png'), url('../images/banner.jpg');
  background-position: top left, top left, center center;
  background-repeat: repeat, repeat, no-repeat;
  background-size: auto, auto, cover;
  z-index: 1;
}

#main-wrapper {
  background: #fff;
  border-top: solid 5px #b1ddab;
  border-bottom: solid 5px #c1cac5;
}

/* Banner */

#banner {
  position: relative;
  background-color: rgba(21, 28, 23, 0.85);
  background-image: url('images/overlay.png'), url('images/highlight.png');
  background-position: top left, center center;
  background-size: auto, 100% 250%;
  background-repeat: repeat, no-repeat;
  text-align: center;
  margin: 0;

  h2 {
    color: #fff;
  }

  p {
    display: block;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #575d59;
  }

  .button {
    @include vendor('transition', ('border-color .2s ease-in-out', 'color .2s ease-in-out'));
    color: #b4b7b5;
    background: transparent !important;
    border: solid 4px #575d59;
    border-color: rgba(87, 93, 89, 0.35);
    border-radius: 16px;

    &:hover {
      color: #e4e7e5;
      border-color: rgba(87, 93, 89, 0.75);
    }

    &:active {
      color: #fff;
      border-color: rgba(97, 103, 99, 1);
    }
  }
}

/* Footer */

#footer {
  a {
    color: inherit;
  }

  h2 {
    &.major span {
      background: #f2f5f3;
    }
  }
}

/* Copyright */

#copyright {
  display: inline-block;
  background: #e7eae8;
  padding: 0.75em 2em 0.75em 2em;
  border-radius: 8px;
  color: #8b9790;

  a {
    color: inherit;
  }

  .menu {
    margin: 0;

    li {
      border-color: #C1CAC5;
      border-width: 2px;
      line-height: 1em;
    }
  }
}

/* Desktop */

@include breakpoint(desktop) {

  /* Basic */

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.5em 0;
  }

  h2 {
    font-size: 1.65em;
    line-height: 1em;

    &.major {
      font-size: 1.65em;
      text-align: center;
      margin: 0 0 3em 0;

      span {
        top: -0.775em;
        padding: 0 1.5em 0 1.5em;
      }
    }
  }

  h3, h4 {
    font-size: 1.25em;
  }

  /* Button */

  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  button,
  .button {
    font-size: 1.25em;
    padding: 0.85em 1.85em;

    &.big {
      font-size: 1.65em;
      padding: 0.85em 1.85em;
    }
  }

  /* List */

  ul {
    &.actions {
      text-align: center;
      margin: 2em 0 0 0;
    }
  }

  /* Box */

  .box {
    &.highlight {
      .special {
        margin: 0 0 4em 0;
      }

      h2 {
        font-size: 3em;
        margin: 0 0 0.75em 0;
      }

      header {
        > p {
          font-size: 1.65em;
          margin: 0 0 1.5em 0;
        }
      }
    }

    &.feature {
      text-align: center;
    }

    &.post {
      header {
        margin: 0 0 2.5em 0;
      }

      h3 {
        font-size: 3em;
        margin: 0 0 0.5em 0;
      }

      header {
        > p {
          font-size: 1.65em;
          margin: 0 0 0.5em 0;
        }
      }

      ul {
        &.meta {
          margin: 0 0 0.5em 0;
        }
      }
    }

    &.post-summary {
      h3 {
        line-height: 1em;
        margin: 0 0 0.75em 0;
      }

      .meta {
        margin: 0;
        line-height: 1em;
      }
    }

    &.page-content {
      header {
        margin: 0 0 2.5em 0;
      }

      h2 {
        font-size: 3em;
        margin: 0 0 0.5em 0;
      }

      header {
        > p {
          font-size: 1.65em;
          margin: 0 0 0.5em 0;
        }
      }

      ul {
        &.meta {
          margin: 0 0 0.5em 0;
        }
      }
    }
  }

  /* Sidebar + Content */

  .sidebar {
    padding-top: 0.5em;

    .homepage & {
      padding-top: 0;
    }

    h2 {
      &.major {
        text-align: left;
        margin: 0 0 1em 0;
        span {
          padding-left: 0;
        }
      }
    }
  }

  .content-left {
    padding-right: 2em;
  }

  .content-right {
    padding-left: 2em;
  }

  /* Wrappers */

  #banner-wrapper {
    padding: 11em 0;
  }

  #main-wrapper {
    border-top-width: 35px;
    border-bottom-width: 10px;
  }

  /* Header */

  #header {
    text-align: center;
    font-size: 2em;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    padding: 3em 0 0 0;
    margin: 0 auto;

    .logo {
      position: relative;
      text-align: center;
      
      div {
        position: relative;
        display: inline-block;
        padding: 0 1.5em 0 1.5em;
        top: -0.65em;
      }

      h1 {
        display: inline;
      }

      p {
        display: inline;
        color: #C1CAC5;
      }
    }
  }

  /* Nav */

  #nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.95);
    background-image: url('images/overlay.png');
    width: 100%;
    height: 3.25em;
    line-height: 3.25em;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    cursor: default;

    ul {
      position: relative;
      z-index: 1001;
    }

    li {
      display: inline-block;
      margin: 0 0.5em 0 0.5em;
      top: 0;
      position: relative;
      -moz-transition: top .15s ease-in-out;
      -webkit-transition: top .15s ease-in-out;
      -o-transition: top .15s ease-in-out;
      -ms-transition: top .15s ease-in-out;
      transition: top .15s ease-in-out;

      > ul {
        display: none;
      }

      a, span {
        @include vendor('transition', ('background-color .075s ease-in-out', 'color .075s ease-in-out'));
        position: relative;
        display: block;
        text-decoration: none;
        color: #6b7770;
        top: -6px;
        padding: 6px 1.5em 0.25em 1.5em;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        outline: 0;
      }

      &:hover, &.active {
        top: 3px;
      }

      &:hover {
        a, span {
          background: #C1CAC5;
          color: #fff;
        }
      }

      &.active {
        a, span {
          background: #C1CAC5;
          color: #fff;
        }
      }

      &.current a {
        background: #b1ddab;
        color: #fff;
      }
    }
  }

  .dropotron {
    background: #6B7770;
    color: #fff;
    border-radius: 6px;
    line-height: 2.75em;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    padding: 1em 0;
    text-align: left;
    min-width: 14em;
    margin-top: -1em;
    box-shadow: 0 1em 2em 0 rgba(0, 0, 0, 0.1);

    li {
      > a, > span {
        display: block;
        color: #D7DAD8;
        text-decoration: none;
        padding: 0 1.25em;
      }

      &:hover {
        > a, > span {
          color: #fff;
          background: #7B8780;
        }
      }

      &.active {
        > a, > span {
          color: #fff;
          background: #7B8780;
        }
      }

      &:first-child {
        border-top: 0;
      }
    }

    &.level-0 {
      margin-top: 1em;
      font-size: 0.9em;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -1em;
        top: -0.65em;
        border-bottom: solid 1em #6B7770;
        border-left: solid 1em transparent;
        border-right: solid 1em transparent;
      }
    }
  }

  /* Banner */
  #banner {
    padding: 4em 0 3.5em 0;

    h2 {
      font-size: 2.75em;
      margin: 0 0 0.75em 0;
    }

    p {
      font-size: 1.35em;
      margin: 0 0 1.25em 0;
    }

    .button {
      font-size: 1.5em;
    }
  }

  /* Main */

  #main {
    padding: 6em 0 6em 0;
  }

  /* Footer */

  #footer {
    text-align: center;
    padding: 8em 0 4em 0;
    h2 {
      &.major {
        margin: 0 0 1em 0;
      }
    }
  }

  /* Copyright */

  #copyright {
    margin: 1em 0 0 0;
  }

}

/* Tablet */

@include breakpoint(tablet) {

  /* Basic */

  body {
    min-width: 960px;
    font-size: 10.25pt;
    line-height: 1.75em;
  }

  input, textarea, select {
    font-size: 10.25pt;
    line-height: 1.75em;
  }

  h2 {
    &.major {
      margin: 0 0 2em 0;

      span {
        top: -0.75em;
        padding: 0 1em 0 1em;
      }
    }
  }

  /* Wrappers */

  #banner-wrapper {
    padding: 6em 0 6em 0;
  }

  #main-wrapper {
    border-top-width: 20px;
    border-bottom-width: 10px;
  }

  /* Header */

  #header {
    padding: 4em 0 1em 0;
  }

  /* Banner */

  #banner {
    padding: 3.5em 0 3em 0;

    h2 {
      margin: 0 0 0.65em 0;
    }

    p {
      margin: 0 0 1em 0;
    }
  }

  /* Main */

  #main {
    padding: 3em 0 2em 0;

    > .row {
      padding: 2em 0 2em 0;
    }
  }

  /* Footer */

  #footer {
    text-align: center;
    padding: 4em 0 4em 0;

    > .row {
      padding: 2em 0 2em 0;
    }
  }

  #footer-wrapper {
    h2 {
      &.major {
        margin: 0 0 1em 0;
      }
    }
  }

  /* Copyright */

  #copyright {
    margin: 0;
  }

}

/* Mobile */

#navPanel, #titleBar {
  display: none;
}

@include breakpoint(mobile) {

  /* Basic */

  html, body {
    overflow-x: hidden;
  }

  body, input, textarea, select {
    line-height: 1.75em;
    font-size: 10pt;
    letter-spacing: 0;
  }

  h2, h3, h4, h5, h6 {
    font-size: 1.5em;
    margin: 0 0 0.5em 0;
  }

  h2 {
    &.major {
      text-align: left;
      margin: 0.775em 0 0.75em 0;

      span {
        top: -0.775em;
        padding: 0 1em 0 0;
      }
    }
  }

  h3 {
    font-size: 1.25em;
  }

  /* Section/Article */

  section, article {
    clear: both;
    margin: 0 0 2.5em 0 !important;
  }

  header p {
    font-size: 1.5em;
    line-height: 1.5em;
  }

  /* Button */

  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  button,
  .button {
    font-size: 1.5em;
    width: 100%;
    padding: 0.75em 0 0.75em 0;
    margin-top: 0.5em;
    text-align: center;
  }

  /* List */

  ul {
    &.special {
      li {
        margin: 0.5em;

        &:hover {
          top: 0;
        }
      }

      a {
        width: 8em;
        height: 8em;
        border-radius: 4.75em;

        &:before {
          font-size: 48px;
        }
      }
    }

    &.contact {
      li {
        margin: 0 0.25em 0.35em 0;
        top: 0 !important;
      }
    }

    &.actions {
      li {
        display: block;
        padding: 0;
        margin: 0.75em 0 0 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &.menu {
      li {
        display: block;
        border: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  /* Box */

  .box {
    &.highlight {
      h2 {
        font-size: 2.25em;
        line-height: 1.25em;
        margin: 0 0 0.5em 0;
      }

      header {
        > p {
          margin: 0 0 0.75em 0;
        }
      }

      br {
        display: none;
      }
    }

    &.post-summary {
      margin: 0 !important;

      h3 {
        font-size: 1.25em;
      }
    }

    &.post {
      h3 {
        font-size: 2.25em;
        line-height: 1.25em;
        margin: 0 0 0.5em 0;
      }

      header {
        > p {
          margin: 0 0 0.75em 0;
        }
      }

      ul {
        &.meta {
          margin: 0 0 0.5em 0;
        }
      }

      header {
        margin: 0 0 3em 0;
      }
    }

    &.page-content {
      h2 {
        font-size: 2.25em;
        line-height: 1.25em;
        margin: 0 0 0.5em 0;
      }

      header {
        > p {
          margin: 0 0 0.75em 0;
        }
      }

      ul {
        &.meta {
          margin: 0 0 0.5em 0;
        }
      }

      header {
        margin: 0 0 3em 0;
      }
    }
  }

  /* Sidebar + Content */

  .sidebar {
    border-top: solid 2px #e7eae8;
    padding-top: 5em;
    margin-top: 5em;
    border-bottom: solid 2px #e7eae8;
    padding-bottom: 5em;

    > section {
      margin: 0 0 5em 0 !important;

      &:last-child {
        margin: 0 !important;
      }
    }
  }

  .content {
    padding-top: 3em;

    .homepage & {
      padding-top: 0;
    }
  }

  /* Wrappers */

  #banner-wrapper {
    padding: 9em 0 5em 0;
  }

  #main-wrapper {
    border-top-width: 0px;
    border-bottom-width: 5px;

    .homepage & {
      border-top-width: 15px;
    }
  }

  /* Header */

  #header {
    display: none;
  }

  /* Nav */

  #nav {
    display: none;
  }

  /* Banner */
  #banner {
    padding: 2.5em 20px;
    margin: 0 !important;
    h2 {
      font-size: 2.25em;
      line-height: 1.25em;
      margin: 0 0 0.5em 0;
    }
    p {
      margin: 0 0 0.5em 0;
    }
  }
  /* Main */

  #main {
    padding: 1em 20px 1em 20px;

    > .row {
      padding: 2em 0 2em 0;
    }
  }

  /* Footer */

  #footer {
    padding: 4em 20px 2em 20px;

    > .row {
      padding: 1em 0 1em 0;
    }
  }

  /* Copyright */

  #copyright {
    width: 100%;
    display: block;

    .menu {
      li {
        line-height: inherit;
      }
    }
  }

  /* Off-Canvas Navigation */

  #page-wrapper {
    @include vendor('backface-visibility', 'hidden');
    @include vendor('transition', 'transform #{_duration(navPanel)} ease');
    padding-bottom: 1px;
  }

  #titleBar {
    @include vendor('backface-visibility', 'hidden');
    @include vendor('transition', 'transform #{_duration(navPanel)} ease');
    display: block;
    height: 44px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: _misc(z-index-base) + 1;
    background-color: rgba(21, 38, 23, 0.8);
    background-image: url('images/overlay.png');

    .title {
      display: block;
      position: relative;
      font-family: 'Open Sans Condensed', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.25em;
      line-height: 44px;
      color: #fff;
      z-index: 1000;
      text-align: center;
    }

    .toggle {
      position: absolute;
      left: 0;
      top: 0;
      width: 80px;
      height: 60px;
      z-index: 1001;

      &:before {
        display: inline-block;
        font-family: FontAwesome;
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 0;
        top: 0;
        background: #b1ddab;
        width: 44px;
        height: 44px;
        content: '\f0c9';
        color: #fff;
        text-align: center;
        line-height: 44px;
        font-size: 20px;
      }

      &:active {
        &:before {
          background-color: #a1cd9b;
        }
      }
    }
  }

  #navPanel {
    @include vendor('backface-visibility', 'hidden');
    @include vendor('transform', 'translateX(#{_size(navPanel) * -1})');
    @include vendor('transition', ('transform #{_duration(navPanel)} ease'));
    display: block;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: _size(navPanel);
    z-index: _misc(z-index-base) + 2;
    background-color: #353c37;
    @include vendor('background-image', ('url("images/overlay.png")', 'linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))'));
    background-repeat: repeat, no-repeat;
    background-size: auto, 100% 100%;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.25em;
    box-shadow: inset -3px 0px 10px 0px rgba(0, 0, 0, 0.25);

    nav {
      position: relative;
      z-index: 1000;
    }

    .link {
      display: block;
      color: inherit;
      text-decoration: none;
      height: 44px;
      line-height: 44px;
      padding: 0 1em 0 1em;
      color: #888;
      color: rgba(255, 255, 255, 0.5);
      border-top: solid 2px rgba(87, 93, 89, 0.15);

      &:first-child {
        border-top: 0;
      }
    }

    .indent-1 {
      display: inline-block;
      width: 1em;
    }

    .indent-2 {
      display: inline-block;
      width: 2em;
    }

    .indent-3 {
      display: inline-block;
      width: 3em;
    }

    .indent-4 {
      display: inline-block;
      width: 4em;
    }

    .indent-5 {
      display: inline-block;
      width: 5em;
    }

    .depth-0 {
      color: #fff;
    }
  }

  body {
    &.navPanel-visible {
      #page-wrapper {
        @include vendor('transform', 'translateX(#{_size(navPanel)})');
      }

      #titleBar {
        @include vendor('transform', 'translateX(#{_size(navPanel)})');
      }

      #navPanel {
        @include vendor('transform', 'translateX(0)');
      }
    }
  }

}

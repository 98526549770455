@import url("font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic|Open+Sans+Condensed:700");
/*
	TXT by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/
/* Reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

/* Box Model */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Containers */
.container {
  margin-left: auto;
  margin-right: auto;
}

.container.\31 25\25		{
  width: 100%;
  max-width: 1200px;
  min-width: 960px;
}

.container.\37 5\25			{
  width: 720px;
}

.container.\35 0\25			{
  width: 480px;
}

.container.\32 5\25			{
  width: 240px;
}

.container {
  width: 960px;
}

@media screen and (min-width: 737px) {
  .container.\31 25\25		{
    width: 100%;
    max-width: 1500px;
    min-width: 1200px;
  }
  .container.\37 5\25			{
    width: 900px;
  }
  .container.\35 0\25			{
    width: 600px;
  }
  .container.\32 5\25			{
    width: 300px;
  }
  .container {
    width: 1200px;
  }
}

@media screen and (min-width: 737px) and (max-width: 1200px) {
  .container.\31 25\25		{
    width: 100%;
    max-width: 1200px;
    min-width: 960px;
  }
  .container.\37 5\25			{
    width: 720px;
  }
  .container.\35 0\25			{
    width: 480px;
  }
  .container.\32 5\25			{
    width: 240px;
  }
  .container {
    width: 960px;
  }
}

@media screen and (max-width: 736px) {
  .container.\31 25\25		{
    width: 100%;
    max-width: 125%;
    min-width: 100%;
  }
  .container.\37 5\25			{
    width: 75%;
  }
  .container.\35 0\25			{
    width: 50%;
  }
  .container.\32 5\25			{
    width: 25%;
  }
  .container {
    width: 100% !important;
  }
}

/* Grid */
.row {
  border-bottom: solid 1px transparent;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.row > * {
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.row:after, .row:before {
  content: '';
  display: block;
  clear: both;
  height: 0;
}

.row.uniform > * > :first-child {
  margin-top: 0;
}

.row.uniform > * > :last-child {
  margin-bottom: 0;
}

.row.\30 \25 > * {
  padding: 0 0 0 0px;
}

.row.\30 \25
{
  margin: 0 0 -1px 0px;
}

.row.uniform.\30 \25 > * {
  padding: 0px 0 0 0px;
}

.row.uniform.\30 \25
{
  margin: 0px 0 -1px 0px;
}

.row > * {
  padding: 0 0 0 40px;
}

.row {
  margin: 0 0 -1px -40px;
}

.row.uniform > * {
  padding: 40px 0 0 40px;
}

.row.uniform {
  margin: -40px 0 -1px -40px;
}

.row.\32 00\25 > * {
  padding: 0 0 0 80px;
}

.row.\32 00\25
{
  margin: 0 0 -1px -80px;
}

.row.uniform.\32 00\25 > * {
  padding: 80px 0 0 80px;
}

.row.uniform.\32 00\25
{
  margin: -80px 0 -1px -80px;
}

.row.\31 50\25 > * {
  padding: 0 0 0 60px;
}

.row.\31 50\25
{
  margin: 0 0 -1px -60px;
}

.row.uniform.\31 50\25 > * {
  padding: 60px 0 0 60px;
}

.row.uniform.\31 50\25
{
  margin: -60px 0 -1px -60px;
}

.row.\35 0\25 > * {
  padding: 0 0 0 20px;
}

.row.\35 0\25
{
  margin: 0 0 -1px -20px;
}

.row.uniform.\35 0\25 > * {
  padding: 20px 0 0 20px;
}

.row.uniform.\35 0\25
{
  margin: -20px 0 -1px -20px;
}

.row.\32 5\25 > * {
  padding: 0 0 0 10px;
}

.row.\32 5\25
{
  margin: 0 0 -1px -10px;
}

.row.uniform.\32 5\25 > * {
  padding: 10px 0 0 10px;
}

.row.uniform.\32 5\25
{
  margin: -10px 0 -1px -10px;
}

.\31 2u, .\31 2u\24
{
  width: 100%;
  clear: none;
  margin-left: 0;
}

.\31 1u, .\31 1u\24
{
  width: 91.6666666667%;
  clear: none;
  margin-left: 0;
}

.\31 0u, .\31 0u\24
{
  width: 83.3333333333%;
  clear: none;
  margin-left: 0;
}

.\39 u, .\39 u\24
{
  width: 75%;
  clear: none;
  margin-left: 0;
}

.\38 u, .\38 u\24
{
  width: 66.6666666667%;
  clear: none;
  margin-left: 0;
}

.\37 u, .\37 u\24
{
  width: 58.3333333333%;
  clear: none;
  margin-left: 0;
}

.\36 u, .\36 u\24
{
  width: 50%;
  clear: none;
  margin-left: 0;
}

.\35 u, .\35 u\24
{
  width: 41.6666666667%;
  clear: none;
  margin-left: 0;
}

.\34 u, .\34 u\24
{
  width: 33.3333333333%;
  clear: none;
  margin-left: 0;
}

.\33 u, .\33 u\24
{
  width: 25%;
  clear: none;
  margin-left: 0;
}

.\32 u, .\32 u\24
{
  width: 16.6666666667%;
  clear: none;
  margin-left: 0;
}

.\31 u, .\31 u\24
{
  width: 8.3333333333%;
  clear: none;
  margin-left: 0;
}

.\31 2u\24 + *,
.\31 1u\24 + *,
.\31 0u\24 + *,
.\39 u\24 + *,
.\38 u\24 + *,
.\37 u\24 + *,
.\36 u\24 + *,
.\35 u\24 + *,
.\34 u\24 + *,
.\33 u\24 + *,
.\32 u\24 + *,
.\31 u\24 + * {
  clear: left;
}

.\-11u {
  margin-left: 91.6666666667%;
}

.\-10u {
  margin-left: 83.3333333333%;
}

.\-9u {
  margin-left: 75%;
}

.\-8u {
  margin-left: 66.6666666667%;
}

.\-7u {
  margin-left: 58.3333333333%;
}

.\-6u {
  margin-left: 50%;
}

.\-5u {
  margin-left: 41.6666666667%;
}

.\-4u {
  margin-left: 33.3333333333%;
}

.\-3u {
  margin-left: 25%;
}

.\-2u {
  margin-left: 16.6666666667%;
}

.\-1u {
  margin-left: 8.3333333333%;
}

@media screen and (min-width: 737px) {
  .row > * {
    padding: 50px 0 0 50px;
  }
  .row {
    margin: -50px 0 -1px -50px;
  }
  .row.uniform > * {
    padding: 50px 0 0 50px;
  }
  .row.uniform {
    margin: -50px 0 -1px -50px;
  }
  .row.\32 00\25 > * {
    padding: 100px 0 0 100px;
  }
  .row.\32 00\25
{
    margin: -100px 0 -1px -100px;
  }
  .row.uniform.\32 00\25 > * {
    padding: 100px 0 0 100px;
  }
  .row.uniform.\32 00\25
{
    margin: -100px 0 -1px -100px;
  }
  .row.\31 50\25 > * {
    padding: 75px 0 0 75px;
  }
  .row.\31 50\25
{
    margin: -75px 0 -1px -75px;
  }
  .row.uniform.\31 50\25 > * {
    padding: 75px 0 0 75px;
  }
  .row.uniform.\31 50\25
{
    margin: -75px 0 -1px -75px;
  }
  .row.\35 0\25 > * {
    padding: 25px 0 0 25px;
  }
  .row.\35 0\25
{
    margin: -25px 0 -1px -25px;
  }
  .row.uniform.\35 0\25 > * {
    padding: 25px 0 0 25px;
  }
  .row.uniform.\35 0\25
{
    margin: -25px 0 -1px -25px;
  }
  .row.\32 5\25 > * {
    padding: 12.5px 0 0 12.5px;
  }
  .row.\32 5\25
{
    margin: -12.5px 0 -1px -12.5px;
  }
  .row.uniform.\32 5\25 > * {
    padding: 12.5px 0 0 12.5px;
  }
  .row.uniform.\32 5\25
{
    margin: -12.5px 0 -1px -12.5px;
  }
  .\31 2u\28desktop\29, .\31 2u\24\28desktop\29
{
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28desktop\29, .\31 1u\24\28desktop\29
{
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28desktop\29, .\31 0u\24\28desktop\29
{
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28desktop\29, .\39 u\24\28desktop\29
{
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28desktop\29, .\38 u\24\28desktop\29
{
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28desktop\29, .\37 u\24\28desktop\29
{
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28desktop\29, .\36 u\24\28desktop\29
{
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28desktop\29, .\35 u\24\28desktop\29
{
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28desktop\29, .\34 u\24\28desktop\29
{
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28desktop\29, .\33 u\24\28desktop\29
{
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28desktop\29, .\32 u\24\28desktop\29
{
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28desktop\29, .\31 u\24\28desktop\29
{
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28desktop\29 + *,
  .\31 1u\24\28desktop\29 + *,
  .\31 0u\24\28desktop\29 + *,
  .\39 u\24\28desktop\29 + *,
  .\38 u\24\28desktop\29 + *,
  .\37 u\24\28desktop\29 + *,
  .\36 u\24\28desktop\29 + *,
  .\35 u\24\28desktop\29 + *,
  .\34 u\24\28desktop\29 + *,
  .\33 u\24\28desktop\29 + *,
  .\32 u\24\28desktop\29 + *,
  .\31 u\24\28desktop\29 + * {
    clear: left;
  }
  .\-11u\28desktop\29
{
    margin-left: 91.6666666667%;
  }
  .\-10u\28desktop\29
{
    margin-left: 83.3333333333%;
  }
  .\-9u\28desktop\29
{
    margin-left: 75%;
  }
  .\-8u\28desktop\29
{
    margin-left: 66.6666666667%;
  }
  .\-7u\28desktop\29
{
    margin-left: 58.3333333333%;
  }
  .\-6u\28desktop\29
{
    margin-left: 50%;
  }
  .\-5u\28desktop\29
{
    margin-left: 41.6666666667%;
  }
  .\-4u\28desktop\29
{
    margin-left: 33.3333333333%;
  }
  .\-3u\28desktop\29
{
    margin-left: 25%;
  }
  .\-2u\28desktop\29
{
    margin-left: 16.6666666667%;
  }
  .\-1u\28desktop\29
{
    margin-left: 8.3333333333%;
  }
}

@media screen and (min-width: 737px) and (max-width: 1200px) {
  .row > * {
    padding: 25px 0 0 25px;
  }
  .row {
    margin: -25px 0 -1px -25px;
  }
  .row.uniform > * {
    padding: 25px 0 0 25px;
  }
  .row.uniform {
    margin: -25px 0 -1px -25px;
  }
  .row.\32 00\25 > * {
    padding: 50px 0 0 50px;
  }
  .row.\32 00\25
{
    margin: -50px 0 -1px -50px;
  }
  .row.uniform.\32 00\25 > * {
    padding: 50px 0 0 50px;
  }
  .row.uniform.\32 00\25
{
    margin: -50px 0 -1px -50px;
  }
  .row.\31 50\25 > * {
    padding: 37.5px 0 0 37.5px;
  }
  .row.\31 50\25
{
    margin: -37.5px 0 -1px -37.5px;
  }
  .row.uniform.\31 50\25 > * {
    padding: 37.5px 0 0 37.5px;
  }
  .row.uniform.\31 50\25
{
    margin: -37.5px 0 -1px -37.5px;
  }
  .row.\35 0\25 > * {
    padding: 12.5px 0 0 12.5px;
  }
  .row.\35 0\25
{
    margin: -12.5px 0 -1px -12.5px;
  }
  .row.uniform.\35 0\25 > * {
    padding: 12.5px 0 0 12.5px;
  }
  .row.uniform.\35 0\25
{
    margin: -12.5px 0 -1px -12.5px;
  }
  .row.\32 5\25 > * {
    padding: 6.25px 0 0 6.25px;
  }
  .row.\32 5\25
{
    margin: -6.25px 0 -1px -6.25px;
  }
  .row.uniform.\32 5\25 > * {
    padding: 6.25px 0 0 6.25px;
  }
  .row.uniform.\32 5\25
{
    margin: -6.25px 0 -1px -6.25px;
  }
  .\31 2u\28tablet\29, .\31 2u\24\28tablet\29
{
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28tablet\29, .\31 1u\24\28tablet\29
{
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28tablet\29, .\31 0u\24\28tablet\29
{
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28tablet\29, .\39 u\24\28tablet\29
{
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28tablet\29, .\38 u\24\28tablet\29
{
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28tablet\29, .\37 u\24\28tablet\29
{
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28tablet\29, .\36 u\24\28tablet\29
{
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28tablet\29, .\35 u\24\28tablet\29
{
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28tablet\29, .\34 u\24\28tablet\29
{
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28tablet\29, .\33 u\24\28tablet\29
{
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28tablet\29, .\32 u\24\28tablet\29
{
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28tablet\29, .\31 u\24\28tablet\29
{
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28tablet\29 + *,
  .\31 1u\24\28tablet\29 + *,
  .\31 0u\24\28tablet\29 + *,
  .\39 u\24\28tablet\29 + *,
  .\38 u\24\28tablet\29 + *,
  .\37 u\24\28tablet\29 + *,
  .\36 u\24\28tablet\29 + *,
  .\35 u\24\28tablet\29 + *,
  .\34 u\24\28tablet\29 + *,
  .\33 u\24\28tablet\29 + *,
  .\32 u\24\28tablet\29 + *,
  .\31 u\24\28tablet\29 + * {
    clear: left;
  }
  .\-11u\28tablet\29
{
    margin-left: 91.6666666667%;
  }
  .\-10u\28tablet\29
{
    margin-left: 83.3333333333%;
  }
  .\-9u\28tablet\29
{
    margin-left: 75%;
  }
  .\-8u\28tablet\29
{
    margin-left: 66.6666666667%;
  }
  .\-7u\28tablet\29
{
    margin-left: 58.3333333333%;
  }
  .\-6u\28tablet\29
{
    margin-left: 50%;
  }
  .\-5u\28tablet\29
{
    margin-left: 41.6666666667%;
  }
  .\-4u\28tablet\29
{
    margin-left: 33.3333333333%;
  }
  .\-3u\28tablet\29
{
    margin-left: 25%;
  }
  .\-2u\28tablet\29
{
    margin-left: 16.6666666667%;
  }
  .\-1u\28tablet\29
{
    margin-left: 8.3333333333%;
  }
}

@media screen and (max-width: 736px) {
  .row > * {
    padding: 20px 0 0 20px;
  }
  .row {
    margin: -20px 0 -1px -20px;
  }
  .row.uniform > * {
    padding: 20px 0 0 20px;
  }
  .row.uniform {
    margin: -20px 0 -1px -20px;
  }
  .row.\32 00\25 > * {
    padding: 40px 0 0 40px;
  }
  .row.\32 00\25
{
    margin: -40px 0 -1px -40px;
  }
  .row.uniform.\32 00\25 > * {
    padding: 40px 0 0 40px;
  }
  .row.uniform.\32 00\25
{
    margin: -40px 0 -1px -40px;
  }
  .row.\31 50\25 > * {
    padding: 30px 0 0 30px;
  }
  .row.\31 50\25
{
    margin: -30px 0 -1px -30px;
  }
  .row.uniform.\31 50\25 > * {
    padding: 30px 0 0 30px;
  }
  .row.uniform.\31 50\25
{
    margin: -30px 0 -1px -30px;
  }
  .row.\35 0\25 > * {
    padding: 10px 0 0 10px;
  }
  .row.\35 0\25
{
    margin: -10px 0 -1px -10px;
  }
  .row.uniform.\35 0\25 > * {
    padding: 10px 0 0 10px;
  }
  .row.uniform.\35 0\25
{
    margin: -10px 0 -1px -10px;
  }
  .row.\32 5\25 > * {
    padding: 5px 0 0 5px;
  }
  .row.\32 5\25
{
    margin: -5px 0 -1px -5px;
  }
  .row.uniform.\32 5\25 > * {
    padding: 5px 0 0 5px;
  }
  .row.uniform.\32 5\25
{
    margin: -5px 0 -1px -5px;
  }
  .\31 2u\28mobile\29, .\31 2u\24\28mobile\29
{
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28mobile\29, .\31 1u\24\28mobile\29
{
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28mobile\29, .\31 0u\24\28mobile\29
{
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28mobile\29, .\39 u\24\28mobile\29
{
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28mobile\29, .\38 u\24\28mobile\29
{
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28mobile\29, .\37 u\24\28mobile\29
{
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28mobile\29, .\36 u\24\28mobile\29
{
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28mobile\29, .\35 u\24\28mobile\29
{
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28mobile\29, .\34 u\24\28mobile\29
{
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28mobile\29, .\33 u\24\28mobile\29
{
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28mobile\29, .\32 u\24\28mobile\29
{
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28mobile\29, .\31 u\24\28mobile\29
{
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28mobile\29 + *,
  .\31 1u\24\28mobile\29 + *,
  .\31 0u\24\28mobile\29 + *,
  .\39 u\24\28mobile\29 + *,
  .\38 u\24\28mobile\29 + *,
  .\37 u\24\28mobile\29 + *,
  .\36 u\24\28mobile\29 + *,
  .\35 u\24\28mobile\29 + *,
  .\34 u\24\28mobile\29 + *,
  .\33 u\24\28mobile\29 + *,
  .\32 u\24\28mobile\29 + *,
  .\31 u\24\28mobile\29 + * {
    clear: left;
  }
  .\-11u\28mobile\29
{
    margin-left: 91.6666666667%;
  }
  .\-10u\28mobile\29
{
    margin-left: 83.3333333333%;
  }
  .\-9u\28mobile\29
{
    margin-left: 75%;
  }
  .\-8u\28mobile\29
{
    margin-left: 66.6666666667%;
  }
  .\-7u\28mobile\29
{
    margin-left: 58.3333333333%;
  }
  .\-6u\28mobile\29
{
    margin-left: 50%;
  }
  .\-5u\28mobile\29
{
    margin-left: 41.6666666667%;
  }
  .\-4u\28mobile\29
{
    margin-left: 33.3333333333%;
  }
  .\-3u\28mobile\29
{
    margin-left: 25%;
  }
  .\-2u\28mobile\29
{
    margin-left: 16.6666666667%;
  }
  .\-1u\28mobile\29
{
    margin-left: 8.3333333333%;
  }
}

/* Basic */
body {
  background: #f2f5f3;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #6b7770;
  font-size: 11.5pt;
  line-height: 2em;
}

body.is-loading * {
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

input, textarea, select {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #6b7770;
  font-size: 11.5pt;
  line-height: 2em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #6b7770;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  text-decoration: none;
  color: inherit;
}

h2.major {
  position: relative;
  border-top: solid 5px #e7eae8;
}

h2.major span {
  background: #fff;
  position: relative;
  display: inline-block;
}

a {
  color: #449038;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

span.float-right {
  float: right;
  margin-right: 0.5em;
}

b, strong {
  font-weight: 700;
  color: #6b7770;
}

i, em {
  font-style: italic;
}

i.float-right, em.float-right {
  float: right;
  margin: 0.3em 0 0 0;
}

br.clear {
  clear: both;
}

sub {
  position: relative;
  top: 0.5em;
  font-size: 0.8em;
}

sup {
  position: relative;
  top: -0.5em;
  font-size: 0.8em;
}

hr {
  border: 0;
  border-top: solid 2px #E7EAE8;
  margin: 0 0 2em 0;
}

blockquote {
  border-left: solid 5px #E7EAE8;
  padding: 1em 0 1em 1.5em;
  font-style: italic;
}

p, ul, ol, dl, table {
  margin-bottom: 3em;
}

/* Section/Article */
section, article {
  margin-bottom: 6em;
}

section > :last-child, section:last-child, article > :last-child, article:last-child {
  margin-bottom: 0;
}

header > p {
  display: block;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #c1cac5;
}

/* Image */
.image {
  position: relative;
  display: inline-block;
  border-radius: 8px;
}

.image img {
  display: block;
  width: 100%;
  border-radius: 8px;
}

.image.no-overlay {
  background: none;
}

.image:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.image:after {
  background: none;
}

.image.fit {
  display: block;
  width: 100%;
}

.image.featured {
  display: block;
  width: 100%;
  margin: 0 0 2em 0;
  background: url("images/overlay.png");
}

.image.left {
  float: left;
  margin: 0 2em 2em 0;
}

.image.centered {
  display: block;
  margin: 0 0 2em 0;
}

.image.centered img {
  margin: 0 auto;
  width: auto;
}

/* Button */
input[type="button"],
input[type="submit"],
input[type="reset"],
button,
.button {
  -webkit-appearance: none;
  display: inline-block;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  background: #b1ddab;
  color: #fff;
  border: 0;
  line-height: 1em;
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  -moz-transition: background-color .2s ease-in-out;
  -webkit-transition: background-color .2s ease-in-out;
  -o-transition: background-color .2s ease-in-out;
  -ms-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
button:hover,
.button:hover {
  background: #c1edbb;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active,
button:active,
.button:active {
  background: #a1cd9b;
}

input[type="button"].alt,
input[type="submit"].alt,
input[type="reset"].alt,
button.alt,
.button.alt {
  background: #c1cac5;
}

input[type="button"].alt:hover,
input[type="submit"].alt:hover,
input[type="reset"].alt:hover,
button.alt:hover,
.button.alt:hover {
  background: #ccd5d0;
}

input[type="button"].alt:active,
input[type="submit"].alt:active,
input[type="reset"].alt:active,
button.alt:active,
.button.alt:active {
  background: #b1bab5;
}

input[type="button"].small,
input[type="submit"].small,
input[type="reset"].small,
button.small,
.button.small {
  line-height: 1.3em;
  font-size: 1em;
  width: 3em;
}

input[type="button"].small.negative,
input[type="submit"].small.negative,
input[type="reset"].small.negative,
button.small.negative,
.button.small.negative {
  background: #fac1c6;
}

input[type="button"].small.negative:hover,
input[type="submit"].small.negative:hover,
input[type="reset"].small.negative:hover,
button.small.negative:hover,
.button.small.negative:hover {
  background: #fdd1d5;
}

/* List */
ul.default {
  list-style: none;
  padding-left: 2em;
}

ul.default li {
  padding-left: 0.5em;
  margin: 3px 0 3px 0;
}

ul.default li:before {
  font-family: 'FontAwesome';
  margin: 0 0.9em 0 -1.2em;
}

ul.default.positive {
  background: #b1ddab;
  margin-bottom: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

ul.default.positive li:before {
  content: '\f067';
}

ul.default.negative {
  background: #fac1c6;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

ul.default.negative li:before {
  content: '\f068';
}

ul.default.info {
  background: #e3f3bc;
  border-radius: 6px;
  margin-top: -2em;
}

ul.default.info li:before {
  content: '\f054';
}

ul.default.checklist {
  display: inline-block;
  margin-top: -1em;
  padding-right: 0.5em;
}

ul.default.checklist li:before {
  content: '\f00c';
}

ul.default.checklist li {
  border-bottom: 1px solid #b1ddab;
}

ul.default.checklist li:last-child {
  border-bottom: none;
}

ul.box {
  list-style: none;
  padding: 0 0.5em 0 0.5em;
}

ul.box.recipe {
  background: #D4EED0;
  margin-bottom: 0;
  border-radius: 6px;
}

ul.box.recipe li {
  border-bottom: 1px solid #b1ddab;
}

ul.box.recipe li:last-child {
  border-bottom: none;
}

ul.box.nutrition {
  background: #D4EED0;
  margin-bottom: 0;
  border-radius: 6px;
}

ul.box.nutrition li {
  border-bottom: 1px solid #b1ddab;
}

ul.box.nutrition li.sub-category {
  margin-left: 1em;
}

ul.box.nutrition li:last-child {
  border-bottom: none;
}

ul.divided > li {
  border-top: solid 2px #e7eae8;
  padding-top: 2.35em;
  margin-top: 2.35em;
}

ul.divided > li:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}

ul.actions li {
  display: inline-block;
  margin: 0 0 0 1em;
}

ul.actions li:first-child {
  margin-left: 0;
}

ul.contact {
  cursor: default;
}

ul.contact li {
  -moz-transition: top .15s ease-in-out;
  -webkit-transition: top .15s ease-in-out;
  -ms-transition: top .15s ease-in-out;
  transition: top .15s ease-in-out;
  position: relative;
  display: inline-block;
  margin: 0 0.25em 0 0.25em;
  top: 0;
}

ul.contact li a {
  -moz-transition: background-color .2s ease-in-out;
  -webkit-transition: background-color .2s ease-in-out;
  -ms-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
  display: block;
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 6px;
  outline: 0;
  background: #c1cac5;
  text-align: center;
}

ul.contact li a:before {
  color: #f2f5f3;
  font-size: 32px;
  line-height: 48px;
}

ul.contact li:hover {
  top: -5px;
}

ul.contact li:hover a.fa-facebook {
  background-color: #3C5A98;
}

ul.contact li:hover a.fa-twitter {
  background-color: #2DAAE4;
}

ul.contact li:hover a.fa-dribbble {
  background-color: #C4376B;
}

ul.contact li:hover a.fa-linkedin {
  background-color: #006599;
}

ul.contact li:hover a.fa-instagram {
  background-color: #2E5E86;
}

ul.contact li:hover a.fa-google-plus {
  background-color: #DA2713;
}

ul.contact li:active {
  top: 0;
}

ul.special {
  cursor: default;
}

ul.special li {
  -moz-transition: top .15s ease-in-out;
  -webkit-transition: top .15s ease-in-out;
  -ms-transition: top .15s ease-in-out;
  transition: top .15s ease-in-out;
  position: relative;
  display: inline-block;
  margin: 0 1.5em 0 1.5em;
  top: 0;
}

ul.special li:hover {
  top: -10px;
}

ul.special li:hover a {
  background: #b9d2ce;
}

ul.special a {
  -moz-transition: background-color .2s ease-in-out;
  -webkit-transition: background-color .2s ease-in-out;
  -ms-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
  display: block;
  position: relative;
  background: #c1cac5;
  width: 12em;
  height: 12em;
  border-radius: 6em;
  outline: 0;
}

ul.special a:before {
  position: absolute;
  width: 128px;
  height: 128px;
  font-size: 96px;
  line-height: 128px;
  left: 50%;
  top: 50%;
  margin-top: -64px;
  margin-left: -64px;
  color: #fff;
  text-align: center;
}

ul.meta {
  font-size: 0.85em;
  color: #a1aaa5;
}

ul.meta li {
  position: relative;
  display: inline-block;
  margin-left: 1em;
}

ul.meta li a {
  color: inherit;
  outline: 0;
}

ul.meta li:first-child {
  margin-left: 0;
}

ul.meta li:before {
  opacity: 0.5;
  cursor: default;
  margin-right: 0.5em;
}

ul.menu li {
  border-left: solid 1px #E7EAE8;
  display: inline-block;
  padding: 0 0 0 0.75em;
  margin: 0 0 0 0.75em;
}

ul.menu li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}

ol.default {
  list-style: decimal;
  padding-left: 1.25em;
}

ol.default li {
  padding-left: 0.25em;
}

/* Forms */
form label {
  display: block;
  margin: 0 0 1em 0;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #6b7770;
}

form input[type="text"],
form input[type="email"],
form input[type="password"],
form select,
form textarea {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  border-radius: 8px;
  border: solid 2px #E7EAE8;
}

form input[type="text"]:focus,
form input[type="email"]:focus,
form input[type="password"]:focus,
form select:focus,
form textarea:focus {
  border-color: #a1cd9b;
  outline: none;
}

form input[type="text"],
form input[type="email"],
form input[type="password"],
form select {
  line-height: 3em;
  padding: 0 1em;
}

form textarea {
  min-height: 9em;
  padding: 1em;
}

form .formerize-placeholder {
  color: #555 !important;
}

form ::-webkit-input-placeholder {
  color: #555 !important;
}

form :-moz-placeholder {
  color: #555 !important;
}

form ::-moz-placeholder {
  color: #555 !important;
}

form :-ms-input-placeholder {
  color: #555 !important;
}

form ::-moz-focus-inner {
  border: 0;
}

/* General Inputs */
input[type="text"],
input[type="number"],
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  border-radius: 8px;
  border: solid 2px #E7EAE8;
  line-height: 3em;
  padding: 0 1em;
}

input[type="text"].small,
input[type="number"].small,
select.small,
textarea.small {
  width: 5em;
  line-height: 2.8em;
}

input[type="text"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
  border-color: #a1cd9b;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Tables */
table {
  width: 100%;
}

table.default.half-width {
  width: 50%;
}

table.default.tight td {
  padding: 0.2em 1em 0.2em 1em;
}

table.default tr {
  border-top: solid 2px #E7EAE8;
}

table.default tr:first-child {
  border-top: 0;
}

table.default tr:hover {
  background-color: #c1edbb;
}

table.default tr:first-child:hover {
  background-color: white;
}

table.default td {
  padding: 0.5em 1em 0.5em 1em;
}

table.default th {
  text-align: left;
  padding: 0.5em 1em 0.5em 1em;
  margin: 0 0 1em 0;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #6b7770;
}

table.default thead {
  background: #E7EAE8;
  color: #fff;
}

/* Box */
.box.highlight {
  text-align: center;
}

.message {
  border: solid 2px;
  padding: 1.5em;
  margin: 1em 0 1em 0;
  border-radius: 4px;
}

.message.success {
  border-color: #b1ddab;
}

.message.error {
  border-color: #fac1c6;
}

.config {
  border: 2px solid #e7eae8;
  padding: 1.5em;
  margin: 1em 0 1em 0;
  border-radius: 8px;
}

.controls {
  margin-bottom: 2em;
}

/* Icons */
.icon {
  position: relative;
  text-decoration: none;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.icon > .label {
  display: none;
}

/* Wrappers */
#banner-wrapper {
  position: relative;
  background-image: url("images/overlay.png"), url("images/overlay.png"), url("../images/banner.jpg");
  background-position: top left, top left, center center;
  background-repeat: repeat, repeat, no-repeat;
  background-size: auto, auto, cover;
  z-index: 1;
}

#main-wrapper {
  background: #fff;
  border-top: solid 5px #b1ddab;
  border-bottom: solid 5px #c1cac5;
}

/* Banner */
#banner {
  position: relative;
  background-color: rgba(21, 28, 23, 0.85);
  background-image: url("images/overlay.png"), url("images/highlight.png");
  background-position: top left, center center;
  background-size: auto, 100% 250%;
  background-repeat: repeat, no-repeat;
  text-align: center;
  margin: 0;
}

#banner h2 {
  color: #fff;
}

#banner p {
  display: block;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #575d59;
}

#banner .button {
  -moz-transition: border-color .2s ease-in-out, color .2s ease-in-out;
  -webkit-transition: border-color .2s ease-in-out, color .2s ease-in-out;
  -ms-transition: border-color .2s ease-in-out, color .2s ease-in-out;
  transition: border-color .2s ease-in-out, color .2s ease-in-out;
  color: #b4b7b5;
  background: transparent !important;
  border: solid 4px #575d59;
  border-color: rgba(87, 93, 89, 0.35);
  border-radius: 16px;
}

#banner .button:hover {
  color: #e4e7e5;
  border-color: rgba(87, 93, 89, 0.75);
}

#banner .button:active {
  color: #fff;
  border-color: #616763;
}

/* Footer */
#footer a {
  color: inherit;
}

#footer h2.major span {
  background: #f2f5f3;
}

/* Copyright */
#copyright {
  display: inline-block;
  background: #e7eae8;
  padding: 0.75em 2em 0.75em 2em;
  border-radius: 8px;
  color: #8b9790;
}

#copyright a {
  color: inherit;
}

#copyright .menu {
  margin: 0;
}

#copyright .menu li {
  border-color: #C1CAC5;
  border-width: 2px;
  line-height: 1em;
}

/* Desktop */
@media screen and (min-width: 737px) {
  /* Basic */
  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.5em 0;
  }
  h2 {
    font-size: 1.65em;
    line-height: 1em;
  }
  h2.major {
    font-size: 1.65em;
    text-align: center;
    margin: 0 0 3em 0;
  }
  h2.major span {
    top: -0.775em;
    padding: 0 1.5em 0 1.5em;
  }
  h3, h4 {
    font-size: 1.25em;
  }
  /* Button */
  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  button,
  .button {
    font-size: 1.25em;
    padding: 0.85em 1.85em;
  }
  input[type="button"].big,
  input[type="submit"].big,
  input[type="reset"].big,
  button.big,
  .button.big {
    font-size: 1.65em;
    padding: 0.85em 1.85em;
  }
  /* List */
  ul.actions {
    text-align: center;
    margin: 2em 0 0 0;
  }
  /* Box */
  .box.highlight .special {
    margin: 0 0 4em 0;
  }
  .box.highlight h2 {
    font-size: 3em;
    margin: 0 0 0.75em 0;
  }
  .box.highlight header > p {
    font-size: 1.65em;
    margin: 0 0 1.5em 0;
  }
  .box.feature {
    text-align: center;
  }
  .box.post header {
    margin: 0 0 2.5em 0;
  }
  .box.post h3 {
    font-size: 3em;
    margin: 0 0 0.5em 0;
  }
  .box.post header > p {
    font-size: 1.65em;
    margin: 0 0 0.5em 0;
  }
  .box.post ul.meta {
    margin: 0 0 0.5em 0;
  }
  .box.post-summary h3 {
    line-height: 1em;
    margin: 0 0 0.75em 0;
  }
  .box.post-summary .meta {
    margin: 0;
    line-height: 1em;
  }
  .box.page-content header {
    margin: 0 0 2.5em 0;
  }
  .box.page-content h2 {
    font-size: 3em;
    margin: 0 0 0.5em 0;
  }
  .box.page-content header > p {
    font-size: 1.65em;
    margin: 0 0 0.5em 0;
  }
  .box.page-content ul.meta {
    margin: 0 0 0.5em 0;
  }
  /* Sidebar + Content */
  .sidebar {
    padding-top: 0.5em;
  }
  .homepage .sidebar {
    padding-top: 0;
  }
  .sidebar h2.major {
    text-align: left;
    margin: 0 0 1em 0;
  }
  .sidebar h2.major span {
    padding-left: 0;
  }
  .content-left {
    padding-right: 2em;
  }
  .content-right {
    padding-left: 2em;
  }
  /* Wrappers */
  #banner-wrapper {
    padding: 11em 0;
  }
  #main-wrapper {
    border-top-width: 35px;
    border-bottom-width: 10px;
  }
  /* Header */
  #header {
    text-align: center;
    font-size: 2em;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    padding: 3em 0 0 0;
    margin: 0 auto;
  }
  #header .logo {
    position: relative;
    text-align: center;
  }
  #header .logo div {
    position: relative;
    display: inline-block;
    padding: 0 1.5em 0 1.5em;
    top: -0.65em;
  }
  #header .logo h1 {
    display: inline;
  }
  #header .logo p {
    display: inline;
    color: #C1CAC5;
  }
  /* Nav */
  #nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.95);
    background-image: url("images/overlay.png");
    width: 100%;
    height: 3.25em;
    line-height: 3.25em;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    cursor: default;
  }
  #nav ul {
    position: relative;
    z-index: 1001;
  }
  #nav li {
    display: inline-block;
    margin: 0 0.5em 0 0.5em;
    top: 0;
    position: relative;
    -moz-transition: top .15s ease-in-out;
    -webkit-transition: top .15s ease-in-out;
    -o-transition: top .15s ease-in-out;
    -ms-transition: top .15s ease-in-out;
    transition: top .15s ease-in-out;
  }
  #nav li > ul {
    display: none;
  }
  #nav li a, #nav li span {
    -moz-transition: background-color .075s ease-in-out, color .075s ease-in-out;
    -webkit-transition: background-color .075s ease-in-out, color .075s ease-in-out;
    -ms-transition: background-color .075s ease-in-out, color .075s ease-in-out;
    transition: background-color .075s ease-in-out, color .075s ease-in-out;
    position: relative;
    display: block;
    text-decoration: none;
    color: #6b7770;
    top: -6px;
    padding: 6px 1.5em 0.25em 1.5em;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    outline: 0;
  }
  #nav li:hover, #nav li.active {
    top: 3px;
  }
  #nav li:hover a, #nav li:hover span {
    background: #C1CAC5;
    color: #fff;
  }
  #nav li.active a, #nav li.active span {
    background: #C1CAC5;
    color: #fff;
  }
  #nav li.current a {
    background: #b1ddab;
    color: #fff;
  }
  .dropotron {
    background: #6B7770;
    color: #fff;
    border-radius: 6px;
    line-height: 2.75em;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    padding: 1em 0;
    text-align: left;
    min-width: 14em;
    margin-top: -1em;
    box-shadow: 0 1em 2em 0 rgba(0, 0, 0, 0.1);
  }
  .dropotron li > a, .dropotron li > span {
    display: block;
    color: #D7DAD8;
    text-decoration: none;
    padding: 0 1.25em;
  }
  .dropotron li:hover > a, .dropotron li:hover > span {
    color: #fff;
    background: #7B8780;
  }
  .dropotron li.active > a, .dropotron li.active > span {
    color: #fff;
    background: #7B8780;
  }
  .dropotron li:first-child {
    border-top: 0;
  }
  .dropotron.level-0 {
    margin-top: 1em;
    font-size: 0.9em;
  }
  .dropotron.level-0:before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -1em;
    top: -0.65em;
    border-bottom: solid 1em #6B7770;
    border-left: solid 1em transparent;
    border-right: solid 1em transparent;
  }
  /* Banner */
  #banner {
    padding: 4em 0 3.5em 0;
  }
  #banner h2 {
    font-size: 2.75em;
    margin: 0 0 0.75em 0;
  }
  #banner p {
    font-size: 1.35em;
    margin: 0 0 1.25em 0;
  }
  #banner .button {
    font-size: 1.5em;
  }
  /* Main */
  #main {
    padding: 6em 0 6em 0;
  }
  /* Footer */
  #footer {
    text-align: center;
    padding: 8em 0 4em 0;
  }
  #footer h2.major {
    margin: 0 0 1em 0;
  }
  /* Copyright */
  #copyright {
    margin: 1em 0 0 0;
  }
}

/* Tablet */
@media screen and (min-width: 737px) and (max-width: 1200px) {
  /* Basic */
  body {
    min-width: 960px;
    font-size: 10.25pt;
    line-height: 1.75em;
  }
  input, textarea, select {
    font-size: 10.25pt;
    line-height: 1.75em;
  }
  h2.major {
    margin: 0 0 2em 0;
  }
  h2.major span {
    top: -0.75em;
    padding: 0 1em 0 1em;
  }
  /* Wrappers */
  #banner-wrapper {
    padding: 6em 0 6em 0;
  }
  #main-wrapper {
    border-top-width: 20px;
    border-bottom-width: 10px;
  }
  /* Header */
  #header {
    padding: 4em 0 1em 0;
  }
  /* Banner */
  #banner {
    padding: 3.5em 0 3em 0;
  }
  #banner h2 {
    margin: 0 0 0.65em 0;
  }
  #banner p {
    margin: 0 0 1em 0;
  }
  /* Main */
  #main {
    padding: 3em 0 2em 0;
  }
  #main > .row {
    padding: 2em 0 2em 0;
  }
  /* Footer */
  #footer {
    text-align: center;
    padding: 4em 0 4em 0;
  }
  #footer > .row {
    padding: 2em 0 2em 0;
  }
  #footer-wrapper h2.major {
    margin: 0 0 1em 0;
  }
  /* Copyright */
  #copyright {
    margin: 0;
  }
}

/* Mobile */
#navPanel, #titleBar {
  display: none;
}

@media screen and (max-width: 736px) {
  /* Basic */
  html, body {
    overflow-x: hidden;
  }
  body, input, textarea, select {
    line-height: 1.75em;
    font-size: 10pt;
    letter-spacing: 0;
  }
  h2, h3, h4, h5, h6 {
    font-size: 1.5em;
    margin: 0 0 0.5em 0;
  }
  h2.major {
    text-align: left;
    margin: 0.775em 0 0.75em 0;
  }
  h2.major span {
    top: -0.775em;
    padding: 0 1em 0 0;
  }
  h3 {
    font-size: 1.25em;
  }
  /* Section/Article */
  section, article {
    clear: both;
    margin: 0 0 2.5em 0 !important;
  }
  header p {
    font-size: 1.5em;
    line-height: 1.5em;
  }
  /* Button */
  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  button,
  .button {
    font-size: 1.5em;
    width: 100%;
    padding: 0.75em 0 0.75em 0;
    margin-top: 0.5em;
    text-align: center;
  }
  /* List */
  ul.special li {
    margin: 0.5em;
  }
  ul.special li:hover {
    top: 0;
  }
  ul.special a {
    width: 8em;
    height: 8em;
    border-radius: 4.75em;
  }
  ul.special a:before {
    font-size: 48px;
  }
  ul.contact li {
    margin: 0 0.25em 0.35em 0;
    top: 0 !important;
  }
  ul.actions li {
    display: block;
    padding: 0;
    margin: 0.75em 0 0 0;
  }
  ul.actions li:first-child {
    margin-top: 0;
  }
  ul.menu li {
    display: block;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  /* Box */
  .box.highlight h2 {
    font-size: 2.25em;
    line-height: 1.25em;
    margin: 0 0 0.5em 0;
  }
  .box.highlight header > p {
    margin: 0 0 0.75em 0;
  }
  .box.highlight br {
    display: none;
  }
  .box.post-summary {
    margin: 0 !important;
  }
  .box.post-summary h3 {
    font-size: 1.25em;
  }
  .box.post h3 {
    font-size: 2.25em;
    line-height: 1.25em;
    margin: 0 0 0.5em 0;
  }
  .box.post header > p {
    margin: 0 0 0.75em 0;
  }
  .box.post ul.meta {
    margin: 0 0 0.5em 0;
  }
  .box.post header {
    margin: 0 0 3em 0;
  }
  .box.page-content h2 {
    font-size: 2.25em;
    line-height: 1.25em;
    margin: 0 0 0.5em 0;
  }
  .box.page-content header > p {
    margin: 0 0 0.75em 0;
  }
  .box.page-content ul.meta {
    margin: 0 0 0.5em 0;
  }
  .box.page-content header {
    margin: 0 0 3em 0;
  }
  /* Sidebar + Content */
  .sidebar {
    border-top: solid 2px #e7eae8;
    padding-top: 5em;
    margin-top: 5em;
    border-bottom: solid 2px #e7eae8;
    padding-bottom: 5em;
  }
  .sidebar > section {
    margin: 0 0 5em 0 !important;
  }
  .sidebar > section:last-child {
    margin: 0 !important;
  }
  .content {
    padding-top: 3em;
  }
  .homepage .content {
    padding-top: 0;
  }
  /* Wrappers */
  #banner-wrapper {
    padding: 9em 0 5em 0;
  }
  #main-wrapper {
    border-top-width: 0px;
    border-bottom-width: 5px;
  }
  .homepage #main-wrapper {
    border-top-width: 15px;
  }
  /* Header */
  #header {
    display: none;
  }
  /* Nav */
  #nav {
    display: none;
  }
  /* Banner */
  #banner {
    padding: 2.5em 20px;
    margin: 0 !important;
  }
  #banner h2 {
    font-size: 2.25em;
    line-height: 1.25em;
    margin: 0 0 0.5em 0;
  }
  #banner p {
    margin: 0 0 0.5em 0;
  }
  /* Main */
  #main {
    padding: 1em 20px 1em 20px;
  }
  #main > .row {
    padding: 2em 0 2em 0;
  }
  /* Footer */
  #footer {
    padding: 4em 20px 2em 20px;
  }
  #footer > .row {
    padding: 1em 0 1em 0;
  }
  /* Copyright */
  #copyright {
    width: 100%;
    display: block;
  }
  #copyright .menu li {
    line-height: inherit;
  }
  /* Off-Canvas Navigation */
  #page-wrapper {
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transition: -moz-transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    -ms-transition: -ms-transform 0.5s ease;
    transition: transform 0.5s ease;
    padding-bottom: 1px;
  }
  #titleBar {
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transition: -moz-transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    -ms-transition: -ms-transform 0.5s ease;
    transition: transform 0.5s ease;
    display: block;
    height: 44px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10001;
    background-color: rgba(21, 38, 23, 0.8);
    background-image: url("images/overlay.png");
  }
  #titleBar .title {
    display: block;
    position: relative;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.25em;
    line-height: 44px;
    color: #fff;
    z-index: 1000;
    text-align: center;
  }
  #titleBar .toggle {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 60px;
    z-index: 1001;
  }
  #titleBar .toggle:before {
    display: inline-block;
    font-family: FontAwesome;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 0;
    top: 0;
    background: #b1ddab;
    width: 44px;
    height: 44px;
    content: '\f0c9';
    color: #fff;
    text-align: center;
    line-height: 44px;
    font-size: 20px;
  }
  #titleBar .toggle:active:before {
    background-color: #a1cd9b;
  }
  #navPanel {
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transform: translateX(-275px);
    -webkit-transform: translateX(-275px);
    -ms-transform: translateX(-275px);
    transform: translateX(-275px);
    -moz-transition: -moz-transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    -ms-transition: -ms-transform 0.5s ease;
    transition: transform 0.5s ease;
    display: block;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 275px;
    z-index: 10002;
    background-color: #353c37;
    background-image: url("images/overlay.png"), -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    background-image: url("images/overlay.png"), -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    background-image: url("images/overlay.png"), -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    background-image: url("images/overlay.png"), linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    background-repeat: repeat, no-repeat;
    background-size: auto, 100% 100%;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.25em;
    box-shadow: inset -3px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }
  #navPanel nav {
    position: relative;
    z-index: 1000;
  }
  #navPanel .link {
    display: block;
    color: inherit;
    text-decoration: none;
    height: 44px;
    line-height: 44px;
    padding: 0 1em 0 1em;
    color: #888;
    color: rgba(255, 255, 255, 0.5);
    border-top: solid 2px rgba(87, 93, 89, 0.15);
  }
  #navPanel .link:first-child {
    border-top: 0;
  }
  #navPanel .indent-1 {
    display: inline-block;
    width: 1em;
  }
  #navPanel .indent-2 {
    display: inline-block;
    width: 2em;
  }
  #navPanel .indent-3 {
    display: inline-block;
    width: 3em;
  }
  #navPanel .indent-4 {
    display: inline-block;
    width: 4em;
  }
  #navPanel .indent-5 {
    display: inline-block;
    width: 5em;
  }
  #navPanel .depth-0 {
    color: #fff;
  }
  body.navPanel-visible #page-wrapper {
    -moz-transform: translateX(275px);
    -webkit-transform: translateX(275px);
    -ms-transform: translateX(275px);
    transform: translateX(275px);
  }
  body.navPanel-visible #titleBar {
    -moz-transform: translateX(275px);
    -webkit-transform: translateX(275px);
    -ms-transform: translateX(275px);
    transform: translateX(275px);
  }
  body.navPanel-visible #navPanel {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
